import React from "react"
import { Link } from "gatsby"
import Logomark from "../images/sheetsync-logomark-white.png"

const Logo = () => (
  <Link to="/">
    <img className="h-6 w-auto sm:h-10 inline mr-2" src={Logomark} alt="Sheetsync" />
  </Link>
)

export default Logo
