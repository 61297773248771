/** @jsx jsx */
/* eslint-disable jsx-a11y/no-autofocus, react/jsx-no-target-blank */
import { jsx } from "theme-ui"
import React from "react"
import { Link, useStaticQuery, graphql, navigate } from "gatsby"
import { FaSearch } from "react-icons/fa"
import { useCombobox } from "downshift"
import Fuse from "fuse.js"

import * as icons from "../utils/icons"
import Logo from "./logo"

import "../styles/tailwind.css"
import "../styles/fonts.css"
import "../styles/global.css"

function SearchInput(props) {
  const [text, setText] = React.useState("")
  const [focused, setFocused] = React.useState(false)

  const data = useStaticQuery(graphql`
    query LayoutQuery {
      site {
        siteMetadata {
          title
          texts {
            searchPlaceholderText
          }
        }
      }
      articles: allMarkdownRemark {
        nodes {
          id
          fields {
            slug
            collection {
              icon
            }
          }
          frontmatter {
            title
            description
          }
          headings {
            # depth
            value
          }
          # excerpt(format: PLAIN)
        }
      }
    }
  `)

  const items = data.articles.nodes

  const fuse = React.useMemo(
    () =>
      new Fuse(items, {
        shouldSort: true,
        tokenize: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        minMatchCharLength: 1,
        keys: [
          "frontmatter.title",
          "frontmatter.description",
          "headings.value",
        ],
      }),
    [items]
  )

  const [inputItems, setInputItems] = React.useState(data.articles.nodes)

  const combobox = useCombobox({
    items: inputItems,
    onInputValueChange: ({ inputValue }) => {
      setInputItems(fuse.search(inputValue).map((node) => node.item))
    },
    itemToString: (node) => (node ? node.frontmatter.title : ""),
    onSelectedItemChange: ({ selectedItem }) => {
      navigate(selectedItem.fields.slug)
    },
  })

  return (
    <div sx={{ position: "relative" }} {...combobox.getComboboxProps()}>
      <label
        htmlFor="search"
        {...combobox.getLabelProps({
          htmlFor: "search",
        })}
        sx={{
          position: "absolute",
          left: "18pt",
          top: "0",
          bottom: "0",
          display: "flex",
          alignItems: "center",
          cursor: "text",
        }}
      >
        <FaSearch color={focused ? "#828A97" : "rgba(255,255,255,0.9)"} />
      </label>
      <input
        id="search"
        type="text"
        value={text}
        autoFocus
        onChange={(event) => setText(event.target.value)}
        placeholder={data.site.siteMetadata.texts.searchPlaceholderText}
        autoComplete="off"
        sx={{
          backgroundColor: "rgba(255,255,255,0.2)",
          transition: "background .4s, box-shadow .2s",
          width: "100%",
          padding: "20px 32px 21px 56px",
          background: "rgba(255,255,255,0.1)",
          border: "none",
          outline: "none",
          color: "searchTextColor",
          fontSize: "18px",
          lineHeight: "18px",
          borderRadius: 2,
          "&:focus": {
            backgroundColor: "white",
            boxShadow: "0 10px 20px rgba(0,0,0,0.14)",
            color: "searchTextFocusColor",
          },
          "::placeholder": {
            color: "searchTextPlaceholderColor",
          },
          "&:focus::placeholder": {
            color: "searchTextFocusPlaceholderColor",
          },
        }}
        {...combobox.getInputProps({
          id: "search",
          onFocus: () => setFocused(true),
          onBlur: () => setFocused(false),
        })}
      />
      <div className="absolute left-0 right-0 items-center bg-white z-20 shadow-2xl shadow-xl rounded-b-lg bg-white"
        {...combobox.getMenuProps()}
        sx={{
          top: "calc(20px + 21px + 18px)",
          cursor: "text"
        }}
      >
        {combobox.isOpen &&
          inputItems.map((node, index) => {
            // skip drafts and "hidden" articles (ones without a collection)
            if (!node.fields || !node.fields.collection) return null

            let icon =
              <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.22766 9C8.77678 7.83481 10.2584 7 12.0001 7C14.2092 7 16.0001 8.34315 16.0001 10C16.0001 11.3994 14.7224 12.5751 12.9943 12.9066C12.4519 13.0106 12.0001 13.4477 12.0001 14M12 17H12.01M21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12Z" stroke="currentColor" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            if (node.icon === "money") {
              icon =
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
            }
            else if (node.icon === "template") {
              icon =
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1} d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z" />
                </svg>
            }

            return (
              <Link key={node.id} to={node.fields.slug} className="flex pl-6 pr-10 py-6 no-underline hover:bg-gray-50 hover:no-underline last:rounded-b-lg"
                {...combobox.getItemProps({ item: node, index })}
              >
                <div className="flex items-center pr-6">
                  <div className="w-8 text-gray-400">
                    {icon}
                  </div>
                </div>
                <div className="system flex-auto text-sm text-gray-900">
                  <p>
                    {node.frontmatter.title}
                  </p>
                  <p className="text-sm text-gray-500 leading-4 mt-1"
                  >
                    {node.frontmatter.description}
                  </p>
                </div>
              </Link>
            )
          })}
      </div>
    </div>
  )
}

class Layout extends React.Component {
  render() {
    const { location, children, description } = this.props
    const rootPath = `${__PATH_PREFIX__}/`

    return (
      <div>

        {/* Header */}
        <div className="bg-blue-700 text-white py-2">
          {location.pathname === rootPath ? (
            <header className="max-w-3xl mx-auto pt-3 pb-5 px-4">
              <Logo />
              <p className="pb-2 mb-2 text-xl">
                {description}
              </p>
              <SearchInput />
            </header>
          ) : (
              <header className="max-w-3xl mx-auto pt-3 pb-5 px-4">
                <Logo />
                <SearchInput />
              </header>
            )}
        </div>

        {/* MAIN */}
        <div className="bg-gray-100">
          <main className="max-w-3xl mx-auto pt-5 pb-1 px-4">
            {children}
          </main>
        </div>

        {/* FOOTER */}
        <div>
          <footer className="max-w-3xl mx-auto pb-8 px-4 text-center -mt-2">
            <div className="text-xs mt-4 text-gray-500">
              <div>
                <p className="mb-5">Can't find an answer to your question? Email us at <a className="underline text-gray-400" href="mailto:support@getsheetsync.com">support@getsheetsync.com</a></p>
                <p className="text-xxs">
                  Built with the help of
                  {` `}
                  <a href="https://help.dferber.de" target="_blank" rel="noopener noreferrer" className="underline text-gray-400">
                    Dom's Help Center
                  </a>
                </p>
              </div>
            </div>
          </footer>
        </div>

      </div>
    )
  }
}

export default Layout
